import { graphql } from "relay-hooks";
import { format } from "date-fns";
import { Text, View } from "tamagui";
import { EventCard } from "../../../components/design-system/EventCard";
import { SectionList } from "react-native";
import { customColorTokens } from "../../../../tamagui.config";
import { useIsFocused } from "@react-navigation/native";
import { useEffect, useState } from "react";
import { supabase } from "../../../../lib/supabase";
import { currentUserAtom } from "../../../../atoms/global";
import { useAtomValue } from "jotai";
import { Event } from "../../../hooks/useGetEvent";

export const MySchedule = ({
  ListHeaderComponent,
}: {
  ListHeaderComponent: React.ReactElement;
}) => {
  const [events, setEvents] = useState<Event[] | null>(null);

  const [futureEvents, setFutureEvents] = useState<Event[] | null>(null);

  const [pastEvents, setPastEvents] = useState<Event[] | null>(null);

  const isFocused = useIsFocused();

  const user = useAtomValue(currentUserAtom);

  const today = new Date();
  const date = format(today, "MM-dd-yy");

  useEffect(() => {
    if (isFocused) {
      supabase
        .from("attendances")
        .select(
          "*, event:events(*, routes(*), host:profiles(avatar_url, name))"
        )

        .eq("user_id", user?.id)
        .order("event(date)", { ascending: true })
        .order("event(time)", { ascending: true })

        .then(({ data, error }) => {
          const eventMap =
            data
              ?.map(
                (attendance: any) =>
                  ({
                    ...attendance.event,
                    route: attendance.event.routes,
                  }) as Event
              )
              ?.reverse() ?? [];
          return setEvents(eventMap ?? []);
        });
    }
  }, [isFocused, setEvents]);

  const groupedData = events?.reduce((acc: any, curr) => {
    const eventMonthYear = format(new Date(curr.date ?? ""), "MMMM yyyy");
    if (!acc[eventMonthYear]) {
      acc[eventMonthYear] = [];
    }
    acc[eventMonthYear].push(curr);
    return acc;
  }, {});

  const sections = groupedData
    ? Object.keys(groupedData).map((key) => ({
        title: key,
        data: groupedData[key],
      }))
    : [];

  const renderSectionHeader = ({
    section: { title },
  }: {
    section: {
      title: string;
    };
  }) => (
    <View bg="$brandParchment" p="$4">
      <Text fontSize={16} fontFamily="$heading" textTransform="uppercase">
        {title}
      </Text>
    </View>
  );

  const renderItem = ({ item }: { item: Event }) => {
    return (
      <View px="$4" mb="$4">
        <EventCard event={item} />
      </View>
    );
  };

  return (
    <SectionList
      ListHeaderComponent={ListHeaderComponent}
      sections={sections}
      keyExtractor={(item) => item.id ?? ""}
      renderItem={renderItem}
      renderSectionHeader={renderSectionHeader}
      style={{ backgroundColor: customColorTokens.brandParchment, flex: 1 }}
      ListEmptyComponent={
        <View
          flex={1}
          justifyContent="center"
          alignItems="center"
          h="100%"
          minHeight={300}
          gap="$4"
        >
          <Text fontSize={20}>{`Looks like your schedule is clear!`}</Text>
        </View>
      }
      contentContainerStyle={{
        paddingBottom: 100,
      }}
    />
  );
};

export const MyScheduleListQueryString = graphql`
  query MyScheduleListQuery($userId: UUID!) {
    attendancesCollection(filter: { user_id: { eq: $userId } }) {
      edges {
        node {
          id
          events {
            id
            date
          }
        }
      }
    }
  }
`;

import React, { useEffect } from "react";

import { ClubNavigator } from "./ClubNavigator";
import { Events } from "../screens/Events";

import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import { TabBar } from "../components/TabBar";
import { useAtomValue } from "jotai";
import * as Device from "expo-device";
import * as Notifications from "expo-notifications";
import Constants from "expo-constants";
import { currentUserAtom } from "../../atoms/global";
import { supabase } from "../../lib/supabase";
import { BcIcon, BikeIcon, EggIcon } from "../components/design-system/icons";
import { Platform } from "react-native";
import { RidesNavigator } from "./RidesNavigator";

async function registerForPushNotificationsAsync() {
  let token;

  if (Device.isDevice) {
    const { status: existingStatus } =
      await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== "granted") {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }
    if (finalStatus !== "granted") {
      // alert("Failed to get push token for push notification!");
      return;
    }
    token = await Notifications.getExpoPushTokenAsync({
      projectId: Constants?.expoConfig?.extra?.eas?.projectId ?? "",
    });
  }

  return token?.data ?? null;
}

export type HomeTabsParamList = {
  rides: undefined;
  events: undefined;
  profile: undefined;
  calendar: undefined;
};

const Tab = createMaterialTopTabNavigator<HomeTabsParamList>();

export const HomeTabsNavigator = () => {
  const user = useAtomValue(currentUserAtom);

  const setExpoPushToken = async (push_token: string | null) => {
    if (!user) throw new Error("No user on the session!");

    const { error } = await supabase
      .from("profiles")
      .update({ push_token })
      .eq("id", user.id);

    if (error) {
      throw error;
    }
  };

  useEffect(() => {
    if (Platform.OS !== "web") {
      registerForPushNotificationsAsync().then((token) =>
        setExpoPushToken(token ?? "")
      );
    }
  }, []);

  return (
    <>
      <Tab.Navigator
        tabBarPosition="bottom"
        tabBar={(props) => <TabBar {...props} />}
        screenOptions={{
          tabBarInactiveTintColor: "gray",
          tabBarActiveTintColor: "black",

          tabBarLabelStyle: {
            fontFamily: "Body",
            fontSize: 10,
          },
        }}
        initialRouteName="rides"
      >
        <Tab.Screen
          component={Events}
          name="events"
          options={{
            title: "Events",
            tabBarIcon: ({ color }) => (
              <BcIcon width={25} height={25} color={color} />
            ),
          }}
        />

        <Tab.Screen
          component={RidesNavigator}
          name="rides"
          options={{
            title: "Rides",
            tabBarIcon: ({ color }) => (
              <BikeIcon
                strokeWidth={1}
                color={color}
                width={Platform.OS === "web" ? 25 : 30}
                height={25}
              />
            ),
          }}
        />
        <Tab.Screen
          name="profile"
          component={ClubNavigator}
          options={{
            title: "Club",
            tabBarIcon: ({ color }) => (
              <EggIcon height={25} width={27} color={color} />
            ),
          }}
        />
      </Tab.Navigator>
    </>
  );
};

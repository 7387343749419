import { Heading, Text, View, YStack, Button as TmButton } from "tamagui";
import { Logo } from "../../components/Logo";
import { useState } from "react";
import { supabase } from "../../../lib/supabase";
import { SafeAreaView } from "react-native-safe-area-context";
import {
  currentUserProfileAtom,
  isAuthenticatedAtom,
} from "../../../atoms/global";
import { useAtomValue, useSetAtom } from "jotai";

import { customColorTokens } from "../../../tamagui.config";
import { useUploadAvatar } from "../../hooks/useUploadAvatar";
import AvatarComponent from "../../components/Avatar";
import { Button } from "../../components/design-system";
import { Loader } from "../../components/design-system/Loader";

export const Avatar = () => {
  const [avatarUrl, setAvatarUrl] = useState("");

  const setAuthenticated = useSetAtom(isAuthenticatedAtom);

  const onAvatarUpload = (url: string) => {
    setAvatarUrl(url);
  };

  const user = useAtomValue(currentUserProfileAtom);

  const { uploadAvatar, avatarUploading } = useUploadAvatar({
    onAvatarUpload,
  });

  const saveAvatar = async () => {
    await supabase
      .from("profiles")
      .update({
        avatar_url: avatarUrl,
      })
      .eq("id", user?.id);

    setAuthenticated(true);
  };

  return (
    <SafeAreaView
      style={{ flex: 1, backgroundColor: customColorTokens.brandParchment }}
    >
      {avatarUploading ? <Loader /> : null}
      <YStack
        justifyContent="space-between"
        flex={1}
        alignItems="flex-start"
        p="$4"
        w="100%"
        gap={10}
      >
        <View w="100%" alignItems="center" justifyContent="center">
          <Logo />
          <Heading w="100%" size={"$2"} mt={32} textTransform="uppercase">
            {`Upload an avatar`}
          </Heading>
        </View>

        <YStack w="100%" flex={1} justifyContent="space-between">
          <View w="100%" justifyContent="center" p="$4">
            <TmButton
              unstyled
              chromeless
              pressStyle={{
                backgroundColor: "transparent",
              }}
              alignItems="center"
              justifyContent="center"
              onPress={() => uploadAvatar(user?.id ?? "")}
              borderWidth={1}
            >
              <View justifyContent="center" alignItems="center">
                <AvatarComponent
                  size={150}
                  imageStyle={{
                    borderRadius: 999,
                  }}
                  url={avatarUrl}
                />
              </View>

              <Text>Upload Photo</Text>
            </TmButton>
          </View>
          <Button w="100%" onPress={saveAvatar} disabled={avatarUploading}>
            CONTINUE
          </Button>
        </YStack>
      </YStack>
    </SafeAreaView>
  );
};

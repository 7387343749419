import { useFonts } from "expo-font";
import * as SplashScreen from "expo-splash-screen";
import { useCallback, useEffect } from "react";
import { AppState, Platform, View } from "react-native";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { TamaguiProvider, Theme } from "tamagui";
import config from "./tamagui.config";
import {
  LinkingOptions,
  NavigationContainer,
  getFocusedRouteNameFromRoute,
} from "@react-navigation/native";
import { BaseNavigator } from "./src/navigators/BaseNavigator";
import { config as RouteConfig } from "./src/navigators/routeConfig";
import * as Linking from "expo-linking";

import "@tamagui/core/reset.css";
import * as Notifications from "expo-notifications";
import { RelayEnvironmentProvider } from "relay-hooks";
import environment from "./src/environment";
import { HelmetProvider } from "react-helmet-async";
import * as Updates from "expo-updates";

if (Platform.OS !== "web") {
  Notifications.setNotificationHandler({
    handleNotification: async () => ({
      shouldShowAlert: true,
      shouldPlaySound: false,
      shouldSetBadge: false,
    }),
  });
}

const prefix = Linking.createURL("/");

const linking: LinkingOptions<ReactNavigation.RootParamList> = {
  prefixes: [
    prefix,
    "breakfastclub://",
    "https://app.breakfastclubatx.com",
    "https://rides.breakfastclubatx.com",
  ],
  config: RouteConfig,
};

async function checkForUpdates() {
  try {
    const update = await Updates.checkForUpdateAsync();

    console.log("checking for updates");
    if (update.isAvailable) {
      await Updates.fetchUpdateAsync();
      // Optional: you can show a message to the user here
      await Updates.reloadAsync();
    }
  } catch (error) {
    // Handle error
    console.log("Error checking for updates:", error);
  }
}

export default function HomeLayout() {
  const [fontsLoaded] = useFonts({
    Inter: require("@tamagui/font-inter/otf/Inter-Regular.otf"),
    InterBold: require("@tamagui/font-inter/otf/Inter-Bold.otf"),
    InterBlack: require("@tamagui/font-inter/otf/Inter-Black.otf"),
    InterBlackItalic: require("@tamagui/font-inter/otf/Inter-BlackItalic.otf"),
    LeagueGothic: require("./assets/fonts/LeagueGothic-Regular.ttf"),
    Souvenir: require("./assets/fonts/Souvenir.ttf"),
  });

  useEffect(() => {
    // Handle notifications when app is in foreground
    const subscription = Notifications.addNotificationResponseReceivedListener(
      (response) => {
        try {
          const url = response?.notification?.request?.content?.data?.url;
          if (url) {
            Linking.openURL(url);
          }
        } catch (error) {
          console.warn("Error handling notification deep link:", error);
        }
      }
    );

    return () => subscription.remove();
  }, []);

  const onLayoutRootView = useCallback(async () => {
    if (fontsLoaded) {
      await SplashScreen.hideAsync();
    }
  }, [fontsLoaded]);

  const url = Linking.useURL();

  useEffect(() => {
    // Do something with url
    console.log(url);
  }, [url]);

  useEffect(() => {
    checkForUpdates();

    const subscription = AppState.addEventListener("change", (nextAppState) => {
      if (nextAppState === "active") {
        checkForUpdates();
      }
    });

    return () => {
      subscription.remove();
    };
  }, []);

  if (!fontsLoaded) {
    return null;
  }

  return (
    <HelmetProvider>
      <RelayEnvironmentProvider environment={environment}>
        <NavigationContainer
          linking={linking}
          documentTitle={{
            formatter: (options, route) => {
              const routeName = route
                ? getFocusedRouteNameFromRoute(route)
                : "";
              return `Breakfast Club - ${options?.title ?? routeName}`;
            },
          }}
        >
          <SafeAreaProvider>
            <TamaguiProvider config={config}>
              <Theme name="light">
                <View onLayout={onLayoutRootView} style={{ display: "none" }} />
                <BaseNavigator />
              </Theme>
            </TamaguiProvider>
          </SafeAreaProvider>
        </NavigationContainer>
      </RelayEnvironmentProvider>
    </HelmetProvider>
  );
}

import { NavigationProp, useNavigation } from "@react-navigation/native";
import React, { useState } from "react";
import { BaseNavigatorParamList } from "../../../navigators/BaseNavigator";
import { Heading, ScrollView, Text, View, YStack, XStack } from "tamagui";
import { Linking, Platform, Pressable } from "react-native";
import { brandShadowSm, customColorTokens } from "../../../../tamagui.config";
import * as Clipboard from "expo-clipboard";
import * as Burnt from "burnt";
import { SafeAreaView } from "react-native-safe-area-context";
import { HeaderButton } from "../../../components/design-system/Button/HeaderButton";
import {
  ArrowLeftIcon,
  LinkIcon,
} from "../../../components/design-system/icons";
import { Dimensions } from "react-native";
import { useGetGroupIcon } from "../../../features/Events/hooks/useGetEventGroupIcons";
import { format, parse } from "date-fns";

import Avatar from "../../../components/Avatar";
import { Role } from "../../../features/Profile/types";
import { RouteCard } from "../../../features/Routes/components/RouteCard";
// import { EventDetailActions } from "./components/EventDetailActions";
import { Pill } from "../../../components/design-system/Pill";
import { getFeet, getMiles } from "../../../features/Routes/utils";
import { EventImage } from "../components/EventImage";
import {
  Edit3,
  FileEdit,
  Pencil,
  Share as TMShare,
} from "@tamagui/lucide-icons";
import { ArrowRightIcon } from "../../../components/design-system/icons/ArrowRightIcon";
import { EventDetailAttendeesModal } from "./components/EventDetailAttendeesModal";
import { useAtomValue } from "jotai";
import { currentUserAtom } from "../../../../atoms/global";
import { Share } from "react-native";

import { useDeleteEvent } from "./hooks/useDeleteEvent";
import { TableManners } from "./components/TableManners";
import { ConfirmationModal } from "../../../components/design-system/ConfirmationModal";
import { EventVisibility } from "./components/EventVisibility";
import { useGetEvent } from "../../../hooks/useGetEvent";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { EventDetailActions } from "./components/EventDetailActions";
import { Loader } from "../../../components/design-system/Loader";

type EventDetailProps = NativeStackScreenProps<
  BaseNavigatorParamList,
  "EditEvent"
>;

export const EventDetail = ({
  route: {
    params: { eventId },
  },
}: EventDetailProps) => {
  const { event, fetchEvent, isFetchingEvent } = useGetEvent(eventId);

  const currentUser = useAtomValue(currentUserAtom);

  const navigation = useNavigation<NavigationProp<BaseNavigatorParamList>>();

  const eventDate = event?.date && parse(event?.date, "yyyy-MM-dd", new Date());

  const timeObj = event?.time && parse(event.time, "HH:mm:ss", new Date());

  const formatedTime = timeObj && format(timeObj, "hh:mm a");

  const formattedDate = eventDate && format(eventDate, "MM/dd/yyyy");

  const screenWidth = Dimensions.get("window").width;

  const groupIcons = event?.groups?.map((g) => {
    const Icon = useGetGroupIcon({ group: g });
    return Icon ? (
      <View {...brandShadowSm} borderWidth={1} borderRadius={999} key={g}>
        <Icon width={32} height={32} key={g} />
      </View>
    ) : null;
  });

  const host = event?.host;
  const hostRole = host?.role;

  const route = event?.route;

  const isClubRide = event?.type === "ClubRide";

  const eventUrl = `https://rides.breakfastclubatx.com/events/${event?.id}`;

  const copyUrl = async () => {
    if (Platform.OS === "web") {
      navigator.clipboard.writeText(eventUrl);
    } else {
      await Clipboard.setUrlAsync(eventUrl);
    }
    Burnt.toast({
      title: "Link copied to clipboard",
      haptic: "success",
    });
  };

  const [isAttendeesModalOpen, setIsAttendeesModalOpen] = useState(false);

  const isCurrentUserHost = host?.user_id === currentUser?.id;

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const deleteEventCallback = () => {
    navigation.goBack();
  };

  const [deleteEvent] = useDeleteEvent(deleteEventCallback);

  const handleDeleteEvent = () => {
    if (event?.id) deleteEvent(event?.id);
  };

  const handleClose = () => {
    navigation.canGoBack() ? navigation.goBack() : navigation.navigate("Home");
  };

  return (
    <View flex={1} bg="$brandParchment" position="relative">
      {isFetchingEvent ? <Loader /> : null}
      <SafeAreaView
        edges={["top"]}
        style={{
          backgroundColor: customColorTokens.brandParchment,
          flex: 1,
        }}
      >
        <ScrollView
          contentContainerStyle={{
            backgroundColor: customColorTokens.brandParchment,
            paddingBottom: 140,
          }}
          flex={1}
          showsVerticalScrollIndicator={false}
        >
          <View
            bg={"$brandParchment"}
            w="100%"
            h={route ? "auto" : 300}
            alignItems="center"
            pos="relative"
            borderBottomWidth={isClubRide ? 0 : 1}
          >
            <View
              zIndex={100}
              w="100%"
              px="$4"
              pb="$4"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <HeaderButton onPress={handleClose}>
                <ArrowLeftIcon width={16} height={16} color="black" />
              </HeaderButton>
              <XStack gap="$2">
                {isCurrentUserHost ? (
                  <HeaderButton
                    onPress={() =>
                      navigation.navigate("EditEvent", {
                        eventId: event?.id ?? "",
                      })
                    }
                  >
                    <Pencil width={20} height={20} />
                  </HeaderButton>
                ) : null}
                {event?.visibility !== "Private" || isCurrentUserHost ? (
                  <HeaderButton
                    onPress={() =>
                      navigation.navigate("ShareEvent", { eventId })
                    }
                  >
                    <TMShare width={20} height={20} />
                  </HeaderButton>
                ) : null}
              </XStack>
            </View>

            <View
              bg="$brandYellow"
              py="$4"
              px="$4"
              borderTopWidth={1}
              w="100%"
              borderBottomWidth={1}
            >
              <Heading
                size="$4"
                textTransform="uppercase"
                fontFamily="$heading"
              >
                {event?.name}
              </Heading>
            </View>

            {route?.image_url && route?.strava_id ? (
              <View w="100%">
                <View pos="relative" borderBottomWidth={1}>
                  <View
                    w={screenWidth}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <RouteCard
                      image_url={route.image_url}
                      strava_id={route.strava_id}
                    />
                  </View>
                </View>
              </View>
            ) : (
              <View
                flex={1}
                bg="$brandParchment"
                w="100%"
                justifyContent="center"
                alignItems="center"
              >
                {event ? (
                  <EventImage
                    width={140}
                    height={140}
                    type={event.type ?? "PopUp"}
                  />
                ) : null}
              </View>
            )}
          </View>

          <XStack
            alignItems="flex-start"
            pt="$4"
            justifyContent="space-between"
          >
            <YStack px="$4" gap="$2" flexShrink={1}>
              <YStack>
                <Text
                  fontFamily="$heading"
                  fontSize={16}
                  textTransform="uppercase"
                >
                  Ride Details
                </Text>
                <EventVisibility visibility={event?.visibility ?? "Public"} />
                {event?.type === "Babes" ? (
                  <Text>
                    A ride for women, women-identifying individuals, and
                    non-binary communities who are comfortable in a space that
                    centers the experiences of women who bike.
                  </Text>
                ) : null}
              </YStack>
              <Text textTransform="uppercase">
                {formattedDate} @ {formatedTime}
              </Text>
              <Text textTransform="uppercase">
                {event?.location ? event?.location : "Location TBD"}
              </Text>
            </YStack>
            {host ? (
              <Pressable
                onPress={() => {
                  navigation.navigate("MemberProfile", { id: host.user_id });
                }}
              >
                <YStack
                  mb="$4"
                  px="$4"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Avatar
                    size={80}
                    url={host?.avatar_url ?? null}
                    imageStyle={{ borderRadius: 999 }}
                    role={hostRole as Role}
                  />
                  <Text textAlign="center" mt="$2">
                    {host?.name}
                  </Text>
                </YStack>
              </Pressable>
            ) : null}
          </XStack>
          <YStack px="$4">
            {event?.description ? (
              <Text>
                {event?.description?.split(" ").map((word, index) => {
                  if (word.startsWith("http")) {
                    return (
                      <>
                        <Text
                          key={word + index}
                          onPress={() => Linking.openURL(word)}
                          color="$blue7Dark"
                        >
                          {word}{" "}
                        </Text>
                        {/* ~ intentional space ~ */}
                      </>
                    );
                  } else {
                    return (
                      <Text key={word + index} style={{ display: "inline" }}>
                        {word + " "}
                      </Text>
                    );
                  }
                })}
              </Text>
            ) : (
              <Text>
                {`We're still working on the details for this one! Check back later for more information!`}
              </Text>
            )}
          </YStack>

          {route ? (
            <XStack
              justifyContent="flex-start"
              alignItems="center"
              gap={"$2"}
              mt="$2"
              flexWrap="wrap"
              px="$4"
            >
              {groupIcons}

              <Pill
                bg="$brandYellow"
                borderColor="black"
                text={`${getMiles(route?.distance ?? 0) ?? ""} mi`}
              />
              <Pill
                bg="$brandYellow"
                borderColor="black"
                text={`${getFeet(route?.elevation ?? 0)} ft`}
              />
              {event?.terrain ? (
                <Pill
                  bg="$brandYellow"
                  borderColor="black"
                  text={event?.terrain}
                />
              ) : null}
            </XStack>
          ) : null}

          <YStack gap="$2" mb="$4" px="$4" mt="$6">
            <Text fontFamily="$heading" fontSize={16} textTransform="uppercase">
              Attendees
            </Text>
            <Pressable onPress={() => setIsAttendeesModalOpen(true)}>
              <XStack justifyContent="space-between" alignItems="center">
                <XStack gap={2} alignItems="center">
                  {event?.attendees
                    ?.slice(0, 4)
                    .map((e) => (
                      <Avatar
                        key={e.id}
                        size={40}
                        url={e.avatar_url ?? null}
                        imageStyle={{ borderRadius: 999 }}
                      />
                    ))}

                  {event?.attendees && event?.attendees.length > 4 ? (
                    <Text textTransform="uppercase">
                      +{event?.attendees.length - 4}{" "}
                      {event?.attendees.length - 4 === 1 ? `other` : `others`}
                    </Text>
                  ) : null}
                </XStack>

                <ArrowRightIcon width={20} height={20} />
              </XStack>
            </Pressable>
          </YStack>

          <TableManners />
          {isCurrentUserHost ? (
            <YStack
              mt={"$4"}
              alignItems="center"
              justifyContent="center"
              gap="$4"
            >
              <Pressable onPress={() => setIsDeleteModalOpen(true)}>
                <Text color="red" fontSize={20}>
                  Delete Ride
                </Text>
              </Pressable>
            </YStack>
          ) : null}
        </ScrollView>
        {event ? (
          <EventDetailActions
            copyUrl={copyUrl}
            refreshQuery={() => fetchEvent()}
            event={event}
          />
        ) : null}
      </SafeAreaView>

      {event ? (
        <EventDetailAttendeesModal
          isOpen={isAttendeesModalOpen}
          onClose={() => setIsAttendeesModalOpen(false)}
          event={event}
        />
      ) : null}

      <ConfirmationModal
        onCancel={() => {
          setIsDeleteModalOpen(false);
        }}
        onConfirm={handleDeleteEvent}
        isOpen={isDeleteModalOpen}
        title="Delete Event?"
        subtitle="Are you sure you want to delete this ride?"
        yesMessage="YES!"
        noMessage="NO!"
      />
    </View>
  );
};

import { Pressable, SectionList } from "react-native";
import { customColorTokens } from "../../../tamagui.config";
import { ScrollView as TGScrollView, Text, View, XStack } from "tamagui";
import { EventCard } from "../../components/design-system/EventCard";

export const ScrollView = motify(TGScrollView)();

import { format } from "date-fns";
import { motify } from "moti";
import { EventType, Group } from "../../features/Events/types";
import { useEffect, useState } from "react";
import { Pill } from "../../components/design-system/Pill";
import { CloseIcon } from "../../components/design-system/icons";
import { useIsFocused } from "@react-navigation/native";
import { supabase } from "../../../lib/supabase";
import { paceOptions } from "../Community/components/Filters";
import { Loader } from "../../components/design-system/Loader";
import { Event } from "../../hooks/useGetEvent";

const eventTypes: EventType[] = [
  "CentralMachineWorks",
  "GravelGang",
  "PopUp",
  "TheMeteorCafe",
  "Babes",
  "SwimClub",
];

export const EventsList = () => {
  const today = new Date();
  const date = format(today, "MM-dd-yy");

  const [eventType, setEventType] = useState<EventType | null>(null);
  const [paceFilter, setPaceFilter] = useState<Group | null>(null);

  const [events, setEvents] = useState<Event[] | null>(null);

  const isFocused = useIsFocused();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isFocused) {
      !events && setLoading(true);
      supabase
        .from("events")
        .select("*, routes(*), host:profiles(avatar_url, name)")
        .filter("date", "gte", date)
        .order("date", { ascending: true })
        .in("type", eventType ? [eventType] : eventTypes)
        .overlaps("groups", paceFilter ? [paceFilter] : paceOptions)

        .then(({ data }) => {
          setLoading(false);

          setEvents(data);
        });
    }
  }, [isFocused, eventType, paceFilter]);

  const handleEventTypePress = (type: EventType) => {
    setEventType(type === eventType ? null : type);
  };

  const handlePacePress = (pace: string) => {
    setPaceFilter(paceFilter === pace ? null : (pace as Group));
  };

  const groupedData = events?.reduce((acc: any, curr) => {
    const eventMonthYear = format(new Date(curr.date ?? ""), "MMMM yyyy");
    if (!acc[eventMonthYear]) {
      acc[eventMonthYear] = [];
    }
    acc[eventMonthYear].push(curr);
    return acc;
  }, {});

  const newSections = groupedData
    ? Object.keys(groupedData).map((key) => ({
        title: key,
        data: groupedData[key],
      }))
    : [];

  const renderSectionHeader = ({
    section: { title },
  }: {
    section: {
      title: string;
    };
  }) => (
    <View bg="$brandParchment" p="$4">
      <Text fontSize={16} fontFamily="$heading" textTransform="uppercase">
        {title}
      </Text>
    </View>
  );

  const renderItem = ({ item }: { item: Event }) => {
    return (
      <View px="$4" mb="$4">
        <EventCard event={item} />
      </View>
    );
  };

  return (
    <View flex={1}>
      {loading ? <Loader /> : null}
      <SectionList
        ListHeaderComponent={() => (
          <View pt="$4" bg="$brandParchment" pb="$1">
            <XStack
              gap="$2"
              bg="$brandParchment"
              pb="$3"
              flexWrap="wrap"
              justifyContent="flex-start"
              alignItems="flex-start"
              px="$4"
            >
              {eventTypes.map((type, i) => (
                <Pressable key={i} onPress={() => handleEventTypePress(type)}>
                  <Pill
                    icon={
                      eventType === type ? (
                        <CloseIcon
                          fill="black"
                          color="black"
                          width={12}
                          height={12}
                        />
                      ) : null
                    }
                    text={humanizeType(type)}
                    bg={"white"}
                    color={"black"}
                    borderColor={"black"}
                  />
                </Pressable>
              ))}
            </XStack>

            <XStack
              gap="$2"
              flexWrap="wrap"
              justifyContent="flex-start"
              alignItems="flex-start"
              px="$4"
            >
              {paceOptions.map((p, i) => (
                <Pressable onPress={() => handlePacePress(p)} key={i}>
                  <Pill
                    icon={
                      paceFilter === p ? (
                        <CloseIcon
                          fill="black"
                          color="black"
                          width={12}
                          height={12}
                        />
                      ) : null
                    }
                    text={p}
                    bg={"white"}
                    color={"black"}
                    borderColor={paceFilter === p ? "black" : "black"}
                  />
                </Pressable>
              ))}
            </XStack>
          </View>
        )}
        sections={newSections}
        keyExtractor={(item) => item.id ?? ""}
        renderItem={renderItem}
        renderSectionHeader={renderSectionHeader}
        stickySectionHeadersEnabled={true}
        style={{ backgroundColor: customColorTokens.brandParchment }}
        contentContainerStyle={{
          paddingBottom: 100,
        }}
        ListEmptyComponent={
          <View flex={1} justifyContent="center" alignItems="center" mt="$16">
            <Text fontSize={16}>{`ON HOLIDAY`}</Text>

            <Text fontSize={16}>{`See you in 2025!`}</Text>
          </View>
        }
      />
    </View>
  );
};

const humanizeType = (type: EventType) => {
  switch (type) {
    case "CentralMachineWorks":
      return "CMW";
    case "GravelGang":
      return "Gravel Gang";
    case "PopUp":
      return "Pop Up";
    case "TheMeteorCafe":
      return "The Meteor";
    case "Babes":
      return "Babes";
    case "SwimClub":
      return "Swim Club";
    default:
      return "All";
  }
};
